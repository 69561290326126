import { inject, Injectable, signal } from "@angular/core";
import { ProposalDocumentUpload } from "@app/core/domain/proposal-status";
import { Course } from "@core/domain/course";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { PlanDetails } from "@core/domain/plan-details";
import { CategoryRequirement, MinorProfile, PlanProfile, UploadedFileInfo } from "@core/domain/plan-profile";
import { StatusMessage, StatusMessageType } from "@core/domain/status-message";
import { ProfileService } from "@core/services/profile.service";
import { ChoiceCourseListBuilder, CourseGroup } from "@feature/profile/services/choice-course-list.builder";
import { TranslateService } from "@ngx-translate/core";
import { CourseDataMapper } from "@shared/services/course-data-mapping.service";
import { LoadingService } from "@shared/services/loading-service";
import { PlanStateService } from "@shared/services/plan-state.service";
import { map, Observable, of, switchMap, tap } from "rxjs";

export enum ProfileModalStep {
  Choice = "choice",
  Confirm = "confirm",
}

@Injectable({
  providedIn: "root",
})
export class ProfileModalStateService {
  // minors is used to keep track of selected courses in the profile minors
  minors: ProfileMinor[] = [];

  // categories is used to keep track of other selected courses
  categories: Category[] = [];

  courseGroups: CourseGroup[] = [];

  profile = signal<PlanProfile | undefined>(undefined);
  totalPoints = signal(0);
  selectedPoints = signal(0);
  creditsAllowedReached = signal(false);
  creditsAllowedExceeded = signal(false);
  warnings = signal([] as StatusMessage[]);
  uploadedFilesInfo: UploadedFileInfo[] = [];
  approveRemark: string | undefined;
  submitDisabled = false;

  private exceededMinAndMaxByOneCourse = false;
  private step = ProfileModalStep.Choice;

  private courseDataMapper = inject(CourseDataMapper);
  private courseHelper = inject(CourseHelper);
  private loadingService = inject(LoadingService);
  private planStateService = inject(PlanStateService);
  private translate = inject(TranslateService);
  private profileService = inject(ProfileService);
  private choiceCourseListBuilder = new ChoiceCourseListBuilder(this.translate, this.courseDataMapper, this);

  initProfile(profile: PlanProfile, step: ProfileModalStep): void {
    this.resetState();
    this.step = step;

    if (profile.categoryRequirements.length) {
      profile.categoryRequirements.forEach((cr) => {
        this.addCategory(cr.category, cr.minimumPoints);
      });
      this.addCategory("", 0);
    } else {
      // Create an array of categories from categories of courses listed on the right side
      this.findAllCategories(profile);
    }

    this.totalPoints.set(profile.pointsPlanned - this.calculateProfilePoints(profile)); // totaal gepland punten - punten van profileringruimte
    this.setProfileMinors(profile);

    profile.individualArrangements.forEach((cursus: Course) => this.individualCourseSelected(cursus));

    this.buildWarnings(profile);

    this.courseGroups = this.choiceCourseListBuilder.buildCourseList(profile);
    this.profile.set(profile);
  }

  nextStep(step: ProfileModalStep): void {
    this.step = step;
    this.warnings.set([]);
    this.profile() && this.buildWarnings(this.profile()!);
  }

  findAllCategories(profile: PlanProfile): void {
    profile.courses.forEach((course: Course) => {
      if (course.category) {
        this.addCategory(course.category, 0);
      }
    });

    profile.minors.forEach((minor) => {
      minor.examComponents.forEach((comp) => {
        comp.courses.forEach((course) => {
          if (course.category) {
            this.addCategory(course.category, 0);
          }
        });
      });
    });
  }

  // Calculate points of all courses that we can select in the profiling space,
  // but that fall within the current plan of the student (if statement within courses loop)
  calculateProfilePoints(profile: PlanProfile): number {
    const points = new Map<string, number>();
    profile.courses.forEach((course: Course) => {
      if (course.statusStudyYear > 0 || course.statusStudyYear === null) {
        points.set(this.courseHelper.getCourseId(course), course.studyPoints);
      }
    });

    profile.minors
      .flatMap((minor) => minor.examComponents)
      .flatMap((comp) => comp.courses)
      .filter((course) => course.statusStudyYear > 0 || course.statusStudyYear === null)
      .forEach((course) => {
        points.set(this.courseHelper.getCourseId(course), course.studyPoints);
      });

    return Array.from(points.values()).reduce((total, coursePoints) => total + coursePoints, 0);
  }

  public individualCourseSelected(course: Course): void {
    const points: number = this.courseHelper.getCoursePoints(course);

    // Update total points to inform other components
    this.totalPoints.set(this.totalPoints() + points);
    this.selectedPoints.set(this.selectedPoints() + points);
  }

  public courseSelected(profile: PlanProfile, course: Course, minor?: string): void {
    let pointsTotal = this.totalPoints();

    if (minor) {
      const profileMinor = this.minors.find((pm) => pm.minor === minor);
      if (profileMinor) {
        if (this.findCourse(profileMinor.courses, course.id)) {
          // course is present in minor
          profileMinor.removeCourse(course);
        } else {
          // course not present in minor
          if (!this.creditsAllowedReached() || this.courseHelper.getCoursePoints(course) === 0) {
            profileMinor.addCourse(course);
          }
        }
      }
    }

    let selectedPoints = this.selectedPoints();
    this.categories
      .filter((category) => category.category === course.category)
      .forEach((category) => {
        const points: number = this.courseHelper.getCoursePoints(course);
        if (this.findCourse(category.courses, course.id)) {
          // course is present in category list
          category.removeCourse(course);

          selectedPoints -= points;
          pointsTotal -= points;

          if (this.exceededMinAndMaxByOneCourse && selectedPoints < profile.maximumPoints) {
            this.exceededMinAndMaxByOneCourse = false;
          }
        } else {
          // course is not present in category list
          if (!this.creditsAllowedReached() || points === 0) {
            category.addCourse(course);
            if (
              profile.minimumPoints !== null &&
              profile.maximumPoints !== null &&
              selectedPoints < profile.minimumPoints &&
              selectedPoints + points >= profile.maximumPoints
            ) {
              this.exceededMinAndMaxByOneCourse = true;
            }

            selectedPoints += points;
            pointsTotal += points;
          }
        }
      });

    // Update total points to inform other components
    this.totalPoints.set(pointsTotal);
    this.selectedPoints.set(selectedPoints);
    this.buildWarnings(profile);
  }

  private findCourse(courses: Course[], id?: number): Course | undefined {
    return courses.find((c) => c.id === id);
  }

  private addCategory(category: string, points: number): void {
    if (!this.categories.some((c) => c.category === category)) {
      this.categories.push(new Category(category, points));
    }
  }

  private determineCreditsAllowed(profile: PlanProfile): void {
    const points = this.selectedPoints();
    let creditsReached = false;
    let creditsExceeded = false;

    if ((profile.maximumPoints && profile.minimumPoints < profile.maximumPoints) || profile.maximumPoints === null) {
      const maxPoints = profile.maximumPoints ? profile.maximumPoints : profile.minimumPoints;
      creditsExceeded = points > maxPoints && !this.exceededMinAndMaxByOneCourse;
    }

    if (!creditsExceeded) {
      if (profile.minimumPoints === profile.maximumPoints || profile.maximumPoints === null) {
        // User can select courses as long as the minimum is not reached.
        // When the minimum is reached, the button 'Next available' is shown and
        // no more courses can be selected.
        // (so the course with which the minimum is exceeded is the last one allowed).
        creditsReached = points >= profile.minimumPoints;
      } else if (profile.maximumPoints && profile.minimumPoints < profile.maximumPoints) {
        creditsReached = points >= profile.maximumPoints;
      }
    }

    this.creditsAllowedReached.set(creditsReached);
    this.creditsAllowedExceeded.set(creditsExceeded);
  }

  setUploadedFiles(uploadedFiles: File[]): void {
    this.uploadedFilesInfo = uploadedFiles.map(
      (uploadedFile) =>
        ({
          file: uploadedFile,
        }) as UploadedFileInfo,
    );
    this.determineSubmitDisabled();
  }

  private buildWarnings(profile: PlanProfile): void {
    this.determineCreditsAllowed(profile);

    this.warnings.set(
      this.step === ProfileModalStep.Choice ? this.buildWarningsChoice(profile) : this.buildWarningsConfirm(),
    );
  }

  private buildWarningsChoice(profile: PlanProfile): StatusMessage[] {
    const warnings: StatusMessage[] = [];

    const hasEnoughPoints = this.selectedPoints() >= profile.minimumPoints;
    if (profile.minorRequirementType && hasEnoughPoints) {
      const minorNotSelected = this.minors.some(
        (pm) => pm.type === profile.minorRequirementType && pm.count < pm.minimumPoints,
      );
      if (minorNotSelected) {
        const msg = this.translate.instant("choiceMinorOrElectives.warningMinorRequirement", {
          name: profile.minorRequirementTypeDescription,
        });
        warnings.push(this.buildWarning(msg));
      }
    }

    if (this.planStateService.hasValidationMessages()) {
      warnings.push(this.buildWarning(this.translate.instant("choiceMinorOrElectives.warningValidation")));
    }

    if (this.creditsAllowedReached()) {
      const msg = this.translate.instant("choiceMinorOrElectives.warningAllowedCreditsReached", {
        name: profile.profileName,
      });
      warnings.push(this.buildWarning(msg));
    }

    if (this.creditsAllowedExceeded()) {
      const msg = this.translate.instant("choiceMinorOrElectives.warningAllowedCreditsExceeded", {
        name: profile.profileName,
      });
      warnings.push(this.buildWarning(msg));
    }

    return warnings;
  }

  private buildWarningsConfirm(): StatusMessage[] {
    const warnings: StatusMessage[] = [];

    // TODO show upload files warning after submit? Show placeholder?
    // TODO show max upload files warning?

    warnings.push(...this.buildWarningsMinorCoursesChecked(this.minors));

    return warnings;
  }

  private buildWarningsMinorCoursesChecked(minors: ProfileMinor[]) {
    const warnings: StatusMessage[] = [];

    minors.forEach((minor) => {
      if (minor.count > 0 && minor.count < minor.minimumPoints) {
        const msg = this.translate.instant("choiceMinorOrElectives.warningMinorNotAllCoursesSelected", {
          minorName: minor.name,
          profileName: this.profile()?.profileName ?? "",
        });
        warnings.push(this.buildWarning(msg));
      }

      // TODO is this enough? Old codebase shows more complex checks...
    });

    return warnings;
  }

  private buildWarning(message: string): StatusMessage {
    return {
      message,
      code: 0,
      type: StatusMessageType.WARNING,
    };
  }

  determineSubmitDisabled(): void {
    const hasRemark = this.approveRemark?.length;
    const requiredFilesUploaded =
      this.profile()?.proposalDocumentToUpload === ProposalDocumentUpload.REQUIRED
        ? !!this.uploadedFilesInfo.length
        : true;

    this.submitDisabled = !(hasRemark && requiredFilesUploaded);
  }

  getCategoryCount(category: CategoryRequirement): number {
    return this.categories.find((c) => c.category === category.category)?.count ?? 0;
  }

  submitApproval(): Observable<boolean> {
    if (this.profile()) {
      // Update the profile: only selected minor/other courses
      const updatedProfile = this.convertProfile(this.profile()!);

      return this.loadingService.present("choiceMinorOrElectives.submittingPlan").pipe(
        switchMap(() =>
          this.profileService.submitPlanApproval(updatedProfile, this.uploadedFilesInfo, this.approveRemark ?? ""),
        ),
        switchMap((planResponse) => {
          if (planResponse) {
            if (planResponse.statusMessages?.length) {
              const warnings = planResponse.statusMessages.map((msg) => this.buildWarning(msg.message));
              this.warnings.set(warnings);
              this.loadingService.dismiss();
              return of(false); // Keep the modal open
            } else {
              // If plan has documents to upload, upload them now
              return this.profileService.uploadDocuments(planResponse as PlanDetails, this.uploadedFilesInfo).pipe(
                tap(() => this.loadingService.dismiss()),
                tap(() => this.resetState()),
                map(() => true), // Close modal
              );
            }
          }

          return of(false);
        }),
      );
    }

    return of(false);
  }

  private convertProfile(profile: PlanProfile): PlanProfile {
    const submitProfile = {
      ...profile,
      minors: [],
      courses: [],
    } as PlanProfile;

    // add all courses from minors
    this.minors.forEach((minor) => {
      if (minor.count >= minor.minimumPoints) {
        const updatedMinor = this.filterUncheckedCourses(minor);
        updatedMinor && submitProfile.minors.push(updatedMinor);
      } else {
        // Minor requirements are not met, add courses as individual (other) courses.
        submitProfile.courses.push(...minor.courses);
      }
    });

    // add all other courses
    const selectedOtherCourses = profile.courses.filter((course: Course) => this.isCourseSelected(course));
    selectedOtherCourses && submitProfile.courses.push(...selectedOtherCourses);

    return submitProfile;
  }

  private filterUncheckedCourses(profileMinor: ProfileMinor): MinorProfile | undefined {
    const minor = this.profile()?.minors.find((m) => profileMinor.isEqual(m));

    if (minor !== undefined) {
      // Should never be undefined
      minor.examComponents.forEach((component) => {
        component.courses = component.courses.filter((course) => this.isSelectedMinorCourse(course, profileMinor));
      });
    }

    return minor;
  }

  private isSelectedMinorCourse(course: Course, profileMinor: ProfileMinor) {
    return profileMinor.courses.some((c) => c.id === course.id);
  }

  isCourseSelected(course: Course): boolean {
    return this.categories.some((category) => category.courses.some((c) => c.id === course.id));
  }

  setProfileMinors(profile: PlanProfile): void {
    this.minors = profile.minors.map(
      (minor) => new ProfileMinor(minor.minor, minor.name, minor.minorStudyProgram, minor.minimumPoints, minor.type),
    );
  }

  private resetState() {
    this.profile.set(undefined);
    this.categories = [];
    this.minors = [];
    this.warnings.set([]);
    this.selectedPoints.set(0);
    this.totalPoints.set(0);
    this.exceededMinAndMaxByOneCourse = false;
    this.uploadedFilesInfo = [];
    this.courseGroups = [];
  }
}

class Category {
  category: string;
  count: number = 0;
  minimumPoints: number;
  courses: Course[] = [];

  constructor(category: string, minimumPoints: number) {
    this.category = category;
    this.minimumPoints = minimumPoints;
  }

  addCourse(course: Course) {
    const found = this.courses.some((c: Course) => c.id === course.id);

    if (!found) {
      this.courses.push(course);
      this.count += course.studyPoints;
    }
  }

  removeCourse(course: Course) {
    const found = this.courses.some((c: Course) => c.id === course.id);

    if (found) {
      this.courses = this.courses.filter((c) => c.id !== course.id);
      this.count -= course.studyPoints;
    }
  }
}

class ProfileMinor {
  minor: string;
  name: string;
  type: string;
  studyProgram: string;
  minimumPoints: number;
  courses: Course[] = [];
  count: number = 0;

  constructor(minor: string, name: string, studyProgram: string, minimumPoints: number, _type: string) {
    this.minor = minor;
    this.name = name;
    this.studyProgram = studyProgram;
    this.minimumPoints = minimumPoints;
    this.type = _type;
  }

  addCourse(course: Course) {
    const found = this.courses.some((c: Course) => c.id === course.id);

    if (!found) {
      this.courses.push(course);
      this.count += course.studyPoints;
    }
  }

  removeCourse(course: Course) {
    const found = this.courses.some((c: Course) => c.id === course.id);

    if (found) {
      this.courses = this.courses.filter((c) => c.id !== course.id);
      this.count -= course.studyPoints;
    }
  }

  isEqual(minor: MinorProfile): boolean {
    return minor.minor === this.minor && minor.name === this.name && minor.type === this.type;
  }
}
