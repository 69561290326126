export type Plan = {
  id: number;
  name: string;
  degreeProgram: string; // (opleiding)
  yearNr: number;
  yearCount: number;
  draft: boolean;
};

export enum RegisteredOrPlanned {
  REGISTERED = "I", // ingeschreven
  PLANNED = "G", // gepland
  NOT_APPLICABLE = "",
}
