import { HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiExamenonderdelenResponse } from "@app/core/api/model/api-examenonderdeel";
import { ExamComponentForPlanCourse } from "@app/core/domain/exam-component";
import { ApiEducations } from "@core/api/model/api-education";
import { Education } from "@core/domain/education";
import { ApiMappingService } from "@core/mapping/api-mapping.service";
import { GenericHttpService } from "@core/services/generic-http.service";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EducationService extends GenericHttpService {
  private mappingService = inject(ApiMappingService);

  getEducations(): Observable<Education[]> {
    const params = new HttpParams().set("plan_app", "true");

    return this.httpGet<ApiEducations>("opleidingen", params).pipe(
      map((educations) => {
        return educations?.items.map((item) => this.mappingService.toEducation(item)) ?? [];
      }),
    );
  }

  getExamComponentsForPlanCourse(planCourseId: number): Observable<ExamComponentForPlanCourse[]> {
    const params = new HttpParams().set("spcu_id", planCourseId.toString());
    return this.httpGet<ApiExamenonderdelenResponse>("examenonderdelen", params).pipe(
      map((apiExamenOnderdelenResponse) => {
        if (!apiExamenOnderdelenResponse || apiExamenOnderdelenResponse.examenonderdelen?.length === 0) {
          return [];
        }
        return apiExamenOnderdelenResponse.examenonderdelen.map((apiExamComponentForPlanCourse) =>
          this.mappingService.toExamComponentForPlanCourse(apiExamComponentForPlanCourse),
        );
      }),
    );
  }
}
