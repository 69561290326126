import { Injectable } from "@angular/core";
import {
  ApiCursusRequest,
  ApiCursussenRequest,
  ApiExamenonderdeelRequest,
  ApiMinorExamenonderdeelRequest,
  ApiMinorsRequest,
  ApiPeriodeRequest,
  ApiPlanRequest,
  ApiProfileringRequest,
  ApiSpecialisatieRequest,
  ApiSpecialisatieRuimteRequest,
  ApiStudiejaarRequest,
} from "@core/api/model/api-plan-request";
import { Course } from "@core/domain/course";
import { ExamComponent } from "@core/domain/exam-component";
import { ApiHelper } from "@core/domain/helpers/api-helper";
import { Period, PlanDetails, StudyYear } from "@core/domain/plan-details";
import { PlanLater, PlanLaterProfile, Specialisation, Specialisations } from "@core/domain/plan-later";
import { MinorProfile, ProfileMinorExamComponent } from "@core/domain/plan-profile";

@Injectable({
  providedIn: "root",
})
export class PlanRequestMappingService {
  public buildApiPlanRequest(plan: PlanDetails, planLater: PlanLater): ApiPlanRequest {
    const includeExamComponents = plan.studyProgramAllowsChangeExamComponent;
    return {
      spla_id: plan.id,
      examenprogramma: plan.studyProgram,
      tweede_examenprogramma: plan.secondStudyProgram,
      studiejaren: plan.studyYears.map((year) => this.mapStudyYear(year, includeExamComponents)),
      losse_cursussen: this.mapOtherCourses(planLater.otherCourses),
      profilering: this.mapProfilering(planLater.profile),
      specialisatieruimte: this.mapSpecialisatieRuimte(planLater.specialisation),
    };
  }

  private mapStudyYear(year: StudyYear, includeExamComponents: boolean): ApiStudiejaarRequest {
    return {
      studiejaar: year.yearNr,
      periodes: year.periods.map((period) => this.mapPeriod(period, includeExamComponents)),
    };
  }

  private mapPeriod(period: Period, includeExamComponents: boolean): ApiPeriodeRequest {
    return {
      periode_nr: period.periodNr,
      periode_oms: period.periodDescription,
      cursussen:
        period.courses
          ?.filter((course) => course.ribbonNr <= 1)
          .map((course) => this.mapCourse(course, includeExamComponents)) ?? [],
    };
  }

  private mapOtherCourses(courses: Course[]): ApiCursussenRequest {
    return {
      cursussen: courses?.filter((course) => course.ribbonNr <= 1).map((course) => this.mapCourse(course)) ?? [],
    };
  }

  private mapCourse(course: Course, includeExamComponents = false): ApiCursusRequest {
    const apiCursusRequest: ApiCursusRequest = {
      cursus: course.code,
      korte_naam: course.name,
      eigen_cursus: course.customCourseName,
      eigen_cursus_punten: course.customCoursePoints ?? null,
      lint_periodes: ApiHelper.numberArrayToString(course.ribbonPeriods),
      plan_later: ApiHelper.booleanToCaciBoolean(course.isInPlanLater),
      spcu_id: course.id,
    };
    if (includeExamComponents) {
      apiCursusRequest.examenonderdeel = course.examComponentCode;
    }
    return apiCursusRequest;
  }

  private mapProfilering(profile: PlanLaterProfile): ApiProfileringRequest {
    return {
      minors: profile.minors.map((minor) => this.mapMinor(minor)),
      cursussen:
        profile.courses?.filter((course) => course.ribbonNr <= 1).map((course) => this.mapCourse(course)) ?? [],
    };
  }

  private mapMinor(minor: MinorProfile): ApiMinorsRequest {
    return {
      examenonderdelen: minor.examComponents.map((examComponent) => this.mapMinorExamComponent(examComponent)),
      minor: minor.minor,
      minor_examenprogramma: minor.studyProgram,
    };
  }

  private mapMinorExamComponent(examComponent: ProfileMinorExamComponent): ApiMinorExamenonderdeelRequest {
    return {
      sple_id: examComponent.id,
      examenonderdeel: examComponent.examComponentCode,
      minor_examenonderdeel: examComponent.minorExamComponentCode,
    };
  }

  private mapSpecialisatieRuimte(specialisations: Specialisations | undefined): ApiSpecialisatieRuimteRequest {
    return {
      specialisaties:
        specialisations?.specialisations.map((specialisation) => this.mapSpecialisation(specialisation)) ?? [],
    };
  }

  private mapSpecialisation(specialisation: Specialisation): ApiSpecialisatieRequest {
    return {
      examenonderdelen: specialisation.examComponents.map((examComponent) => this.mapExamenonderdeel(examComponent)),
      specialisatie: specialisation.code,
      spec_examenprogramma: specialisation.studyProgramCode,
      // tweede_examenprogramma: specialisation.secondStudyProgram, // TODO welk veld?
    };
  }

  private mapExamenonderdeel(examComponent: ExamComponent): ApiExamenonderdeelRequest {
    return {
      sple_id: examComponent.id,
      examenonderdeel: examComponent.examComponentCode,
      spec_examenonderdeel: examComponent.examComponentCode,
    };
  }
}
