import { inject, Injectable } from "@angular/core";
import { Course } from "@core/domain/course";
import { CurriculumCourse } from "@core/domain/curriculum";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { PlanDetails } from "@core/domain/plan-details";
import { CourseData } from "@shared/types/course-data";
import { PlanLaterType } from "@shared/types/plan-later-types";
import { PlanStateService } from "./plan-state.service";

@Injectable({
  providedIn: "root",
})
export class CourseDataMapper {
  private courseHelper = inject(CourseHelper);
  private planStateService = inject(PlanStateService);

  mapCourseData(
    course: Course | CurriculumCourse,
    options: {
      selectedPlan?: PlanDetails;
      isPlannable?: boolean;
      isRemovable?: boolean;
      planLaterType?: PlanLaterType;
    } = { isPlannable: false, isRemovable: true },
  ): CourseData {
    const plan = options.selectedPlan || this.planStateService.currentSelectedPlan();
    const plannedCourse = this.courseHelper.findPlannedCourse(course, plan);
    const isCoursePlannable = plannedCourse ? false : this.courseHelper.isCoursePlannable(course);
    const isCourseRemovable = options.isRemovable ? this.courseHelper.isRemovableFromPlanLater(course) : false;

    const courseData = {
      ...course,
      labelColor: this.courseHelper.getLabelColor(course),
      hasRibbonPeriods: this.courseHelper.hasRibbonPeriods(course),
      isRibbonCourse: this.courseHelper.isRibbonCourse(course),
      isCourseObtained: this.courseHelper.isCourseObtained(course),
      isCustomCourse: this.courseHelper.isCustomCourse(course),
      isPlannable: options.isPlannable && isCoursePlannable,
      isPlannedCourse: !!plannedCourse,
      plannedText: plannedCourse ? this.courseHelper.getPlannedText(plannedCourse) : "",
      isRemovable: isCourseRemovable,
      isAddedToPlanLater: false, // Default false, may be updated after this
      isPartOfPlanLater: course.isInPlanLater,
      statusObtained: plannedCourse?.statusObtained ?? course.statusObtained,
      statusObtainedLabel: this.courseHelper.getStatusObtainedLabel(course),
      isRegistered: !!plannedCourse?.isRegistered,
      isRegisteredInPlanPeriod: plannedCourse ? this.courseHelper.courseRegisteredInPlanPeriod(plannedCourse) : false,
      planLaterType: options.planLaterType,
      isMovableToPlanLater: this.courseHelper.isMovableToPlanLater(course, plan),
      statusStudyYear: plannedCourse?.statusStudyYear ?? course.statusStudyYear,
      periodDescription: plannedCourse?.periodDescription ?? course.periodDescription,
      statusPeriod: plannedCourse?.statusPeriod ?? course.statusPeriod,
      planId: plan?.id,
    } as CourseData;

    if (plan) {
      // Course planId may have an undefined value
      courseData.planId = course.planId > 0 ? course.planId : plan.id;
      courseData.academicYear = course.academicYear ?? (plan.yearNr || 0);
    }

    return courseData;
  }
}
