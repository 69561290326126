import { Course } from "@core/domain/course";
import { Condition } from "@core/domain/plan-profile";

export type Courses = {
  courses: Course[];
};

export type Minors = {
  minors: Minor[];
};

export enum ExamComponentType {
  MANDATORY = "V", // Verplicht
  MANDATORY_CHOICE = "VK", // Verplichte keuze
  CHOICE = "K", // Keuze
}

export enum ExamComponentMandatoryChoiceType {
  LIST = "L", // Lijst
  CONDITION = "V", // Vrij veld conditie
  NOT_APPLICABLE = "",
}

type ExamComponentBase = {
  id: number;
  label: string;
  labelColor: string;
  type?: ExamComponentType;
  typeMandatoryChoice?: ExamComponentMandatoryChoiceType;
  minimumPoints?: number;
  maximumPoints?: number;
  conditionsMet: boolean;
  conditionsMetMandatoryOrMandatoryChoice: boolean;
};

export type ExamComponent = ExamComponentBase & {
  studyProgram?: string;
  examComponentCode: string;
  examComponentName: string;
  examComponentExplanation: string;
  conditions?: Condition[];
} & Courses;

export type MinorBase = {
  minor: string;
  name: string;
  type: string;
};

export type Minor = MinorBase & {
  examComponentCode: string;
  examComponents: MinorExamComponent[];
} & Courses;

export type MinorExamComponent = ExamComponentBase & {
  minorExamComponentCode: string;
  minorExamComponentName: string;
  minorExamComponentExplanation: string;
  componentTypes: Record<string, string>; // V, VK, S, P, K
} & Courses;

export type ExamComponentForPlanCourse = {
  examComponentCode: string;
  examComponentName: string;
};
