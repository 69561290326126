import { inject, Injectable } from "@angular/core";
import { ApiCursus, ApiCursussen } from "@core/api/model/api-cursus";
import {
  ApiExamenonderdeel,
  ApiExamenonderdelen,
  ApiMinorExamenonderdeel,
  ApiVoorwaarde,
} from "@core/api/model/api-examenonderdeel";
import {
  ApiPlanLater,
  ApiPlanLaterProfilering,
  ApiProfielMinor,
  ApiSpecialisatie,
  ApiSpecialisatieExamenonderdeel,
  ApiSpecialisatieRuimte,
} from "@core/api/model/api-plan-later";
import { ApiStatusmelding } from "@core/api/model/api-statusmelding";
import { Course } from "@core/domain/course";
import { ExamComponent } from "@core/domain/exam-component";
import { ApiHelper } from "@core/domain/helpers/api-helper";
import { StatusMessageHelper } from "@core/domain/helpers/status-message-helper";
import { PlanLater, PlanLaterProfile, Specialisation, Specialisations } from "@core/domain/plan-later";
import { Condition, MinorProfile, ProfileMinorExamComponent } from "@core/domain/plan-profile";
import { StatusMessage } from "@core/domain/status-message";
import { CourseMappingService } from "@core/mapping/course-mapping.service";

@Injectable({
  providedIn: "root",
})
export class PlanLaterMappingService {
  private courseMapper = inject(CourseMappingService);
  private messageHelper = inject(StatusMessageHelper);

  private planLater!: PlanLater;

  mapPlanLater(apiPlanLater: ApiPlanLater): PlanLater {
    this.planLater = {
      id: apiPlanLater.spla_id,
      name: apiPlanLater.planning_naam,
      degreeProgram: apiPlanLater.opleiding,
      yearNr: apiPlanLater.start_jaar,
      yearCount: apiPlanLater.aantal_jaar,
      draft: ApiHelper.caciBooleanToBoolean(apiPlanLater.concept_planning),
      statusMessages: this.extractStatusMessages(apiPlanLater.statusmeldingen) ?? [],
      mandatory: this.extractExamComponents(apiPlanLater.verplicht) ?? [],
      mandatoryChoice: this.extractExamComponents(apiPlanLater.verplichte_keuze) ?? [],
      specialisation: this.extractSpecialisations(apiPlanLater.specialisatieruimte) ?? undefined,
      profile: this.extractProfile(apiPlanLater.profilering) ?? [],
      replacements: this.extractReplacements(apiPlanLater.vervangingsregelingen) ?? [],
      otherCourses: this.extractOtherCourses(apiPlanLater.losse_cursussen) ?? [],
    };

    return this.planLater;
  }

  private extractStatusMessages(statusmeldingen?: ApiStatusmelding[]): StatusMessage[] {
    if (statusmeldingen) {
      const messages = statusmeldingen.map((msg) => ApiHelper.toStatusMessage(msg));

      if (messages) {
        return messages.filter((msg) => this.messageHelper.isGenericStatusMessage(msg));
      }
    }

    return [];
  }

  private extractExamComponents(examComponents?: ApiExamenonderdelen): ExamComponent[] {
    const examComponentsResult: ExamComponent[] = [];

    if (examComponents?.examenonderdelen?.length) {
      examComponentsResult.push(...examComponents.examenonderdelen.map((comp) => this.toExamComponent(comp)));
    }

    return examComponentsResult;
  }

  private toExamComponent(examenonderdeel: ApiExamenonderdeel): ExamComponent {
    return {
      id: examenonderdeel.sple_id,
      studyProgram: examenonderdeel.examenprogramma,
      examComponentCode: examenonderdeel.examenonderdeel,
      examComponentName: examenonderdeel.examenonderdeel_naam,
      examComponentExplanation: examenonderdeel.examenonderdeel_toelichting,
      label: examenonderdeel.label,
      labelColor: examenonderdeel.kleur,
      typeMandatoryChoice: examenonderdeel.type_vk,
      minimumPoints: examenonderdeel.minimum_punten,
      maximumPoints: examenonderdeel.maximum_punten,
      conditionsMet: ApiHelper.caciBooleanToBoolean(examenonderdeel.voldaan),
      conditionsMetMandatoryOrMandatoryChoice: ApiHelper.caciBooleanToBoolean(examenonderdeel.voldaan_vk),
      courses: examenonderdeel.cursussen.map((cursus) => this.toCourse(cursus)),
      conditions: examenonderdeel.voorwaarden?.map((voorwaarde) => this.mapCondition(voorwaarde)),
    };
  }

  private toCourse(cursus: ApiCursus): Course {
    return this.courseMapper.mapCourse(cursus);
  }

  private mapCondition(voorwaarde: ApiVoorwaarde): Condition {
    return {
      section: voorwaarde.rubriek,
      sectionDescription: voorwaarde.rubriek_oms,
      sectionNumber: voorwaarde.volgnummer_rubriek,
      freeField: voorwaarde.vrij_veld,
      freeFieldDescription: voorwaarde.vrij_veld_oms,
      content: voorwaarde.inhoud,
    };
  }

  private extractProfile(apiProfile: ApiPlanLaterProfilering): PlanLaterProfile {
    return {
      // studyProgram: apiProfile.examenprogramma,
      examComponentCode: apiProfile.examenonderdeel,
      examComponentName: apiProfile.examenonderdeel_naam,
      // examComponentExplanation: apiProfile.examenonderdeel_toelichting,
      label: apiProfile.label,
      labelColor: apiProfile.kleur,
      // typeMandatoryChoice: apiProfile.type_vk,
      minimumPoints: apiProfile.minimum_punten,
      // maximumPoints: apiProfile.maximum_punten,
      conditionsMet: ApiHelper.caciBooleanToBoolean(apiProfile.voldaan),
      // conditionsMetMandatoryOrMandatoryChoice: ApiHelper.caciBooleanToBoolean(apiProfile.voldaan_vk),
      minorRequirementType: apiProfile.profil_minor_eis_type,
      minorRequirementTypeDescription: apiProfile.profil_minor_eis_type_oms,
      courses: apiProfile.cursussen.map((cursus: ApiCursus) => this.toCourse(cursus)),
      minors: apiProfile.minors.map((minor) => this.toMinor(minor)),
    };
  }

  private toMinor(apiMinor: ApiProfielMinor): MinorProfile {
    return {
      minor: apiMinor.minor,
      name: apiMinor.naam,
      type: apiMinor.type,

      code: apiMinor.code,
      examComponents: apiMinor.examenonderdelen.map((comp) => this.toProfileMinorExamComponent(comp)),
      content: apiMinor.inhoud,
      typeBama: apiMinor.type_bama,
      typePart: apiMinor.type_onderdeel,
      isRegistered: ApiHelper.caciBooleanToBoolean(apiMinor.status_ingeschreven),
      minorStudyProgram: apiMinor.minor_examenprogramma,
      studyProgram: apiMinor.minor_examenprogramma,
      minimumPoints: apiMinor.minimum_punten,
    } as MinorProfile;
  }

  private toProfileMinorExamComponent(component: ApiMinorExamenonderdeel): ProfileMinorExamComponent {
    return {
      id: component.sple_id,
      minorExamComponentCode: component.minor_examenonderdeel,
      minorExamComponentName: component.minor_onderdeel_naam,
      // minorExamComponentExplanation: component.examenonderdeel_toelichting,
      label: component.label,
      labelColor: component.kleur,
      type: component.type,
      typeMandatoryChoice: component.type_vk,
      minimumPoints: component.minimum_punten,
      conditionsMet: ApiHelper.caciBooleanToBoolean(component.voldaan),
      conditionsMetMandatoryOrMandatoryChoice: ApiHelper.caciBooleanToBoolean(component.voldaan_vk),
      componentTypes: component.onderdeel_types,
      courses: component.cursussen.map((cursus: ApiCursus) => this.toCourse(cursus)),
    } as ProfileMinorExamComponent;
  }

  private extractReplacements(apiReplacementCourses?: ApiCursussen): Course[] | undefined {
    return apiReplacementCourses?.cursussen.map((cursus: ApiCursus) => this.toCourse(cursus));
  }

  private extractOtherCourses(apiOtherCourses: ApiCursussen): Course[] | undefined {
    return apiOtherCourses?.cursussen.map((cursus: ApiCursus) => this.toCourse(cursus));
  }

  private extractSpecialisations(apiSpecialisations?: ApiSpecialisatieRuimte | undefined): Specialisations | undefined {
    if (!apiSpecialisations) {
      return undefined;
    }

    return {
      examComponentCode: apiSpecialisations.examenonderdeel,
      examComponentName: apiSpecialisations.examenonderdeel_naam,
      // examComponentExplanation: apiSpecialisations.examenonderdeel_toelichting,
      label: apiSpecialisations.label,
      labelColor: apiSpecialisations.kleur,
      minimumPoints: apiSpecialisations.minimum_punten,
      maximumPoints: apiSpecialisations.maximum_punten,
      conditionsMet: ApiHelper.caciBooleanToBoolean(apiSpecialisations.voldaan),
      // conditionsMetMandatoryOrMandatoryChoice: apiSpecialisations.voldaan_vk,
      specialisations: apiSpecialisations.specialisaties?.map(
        (specialisation) => this.extractSpecialisation(specialisation) ?? [],
      ),
    };
  }

  private extractSpecialisation(apiSpecialisation: ApiSpecialisatie): Specialisation {
    return {
      code: apiSpecialisation.specialisatie,
      name: apiSpecialisation.naam,
      studyProgramCode: apiSpecialisation.spec_examenprogramma,
      planningState: apiSpecialisation.status_planning,
      examComponents: apiSpecialisation.examenonderdelen?.map((comp) => this.toSpecialisationExamComponent(comp)) ?? [],
      inPlanLater: true,
    };
  }

  private toSpecialisationExamComponent(examComponent: ApiSpecialisatieExamenonderdeel): ExamComponent {
    return {
      id: examComponent.sple_id,
      examComponentCode: examComponent.spec_examenonderdeel,
      examComponentName: examComponent.spec_onderdeel_naam,
      examComponentExplanation: examComponent.spec_examenonderdeel_toelichting,
      label: examComponent.label,
      labelColor: examComponent.kleur,
      type: examComponent.type,
      typeMandatoryChoice: examComponent.type_vk,
      minimumPoints: examComponent.minimum_punten,
      maximumPoints: examComponent.maximum_punten,
      conditionsMet: ApiHelper.caciBooleanToBoolean(examComponent.voldaan),
      conditionsMetMandatoryOrMandatoryChoice: ApiHelper.caciBooleanToBoolean(examComponent.voldaan_vk),
      courses: examComponent.cursussen?.map((course: ApiCursus) => this.toCourse(course)) ?? [],
    };
  }
}
